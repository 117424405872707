import Vue from "vue";
import Vuex from "vuex";
import user from "./user.module";
import registerDetails from "./registerDetails.module";
import AIChat from "./AIChat.module";
import captcha from "./captcha.module";
import ipGeo from "./ipGeo.module";
import profile from "./profile.module";
import password from "./password.module";
import payment from "./payment.module";
import analytics from "./analytics.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    registerDetails,
    AIChat,
    captcha,
    ipGeo,
    profile,
    password,
    payment,
    analytics
  }
});
