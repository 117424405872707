<template>
  <el-container>
    <ChatAI></ChatAI>
    <div id="feedback">
      <el-tooltip class="item" effect="dark" content="Feedback" placement="top">
        <span class="contact-help text-warning" @click="goToFeedbackPage">
          <i class="fas fa-bug"></i>
        </span>
      </el-tooltip>
    </div>
    <el-header v-if="showHeader">
      <Header :theme="theme" />
    </el-header>
    <el-main
      :key="`${$route.fullPath}Page`"
      :class="isIpad ? 'ipad' : ''"
      style="padding-top:60px"
    >
      <div v-if="existAdBlock" class="container">
        <PageTitle>
          {{ getSimpleTitle(this.$route.name) }}
        </PageTitle>
      </div>
      <router-view v-else />
    </el-main>
    <template v-if="showFooter">
      <Footer />
    </template>
    <el-backtop
      :right="isPhone ? 15 : 40"
      :bottom="isPhone ? 40 : 100"
    ></el-backtop>
  </el-container>
</template>

<script>
import baseDomainPath from "@/common/baseDomainPath";
import Theme from "@/common/theme";
import ChatAI from "@/views/chatAI/Index.vue";

import { user } from "@ivy-way/material";
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import PageTitle from "@/components/PageTitle.vue";
import Footer from "@/components/Footer";
import Header from "@/components/Header.vue";

export default {
  metaInfo() {
    return {
      title: this.CompanyName
    };
  },

  components: {
    PageTitle,
    Header,
    Footer,
    ChatAI
  },

  mixins: [],

  props: [],
  watch: {},
  data() {
    return {
      countryCode: "",
      existAdBlock: false,
      showMain: true
    };
  },
  computed: {
    ...mapState("user", ["token", "lang", "profile"]),
    emailIsConfirmed() {
      return this.profile.email_verified_at ? true : false;
    },
    isLogin() {
      // TODO: 應該還要檢查 token 是否合法？
      return this.token !== "";
    },
    ivyWayToken() {
      let token = 0;
      if (this.$route.query.token) {
        token = this.$route.query.token;
      }
      return token;
    },
    baseDomainPath() {
      return baseDomainPath;
    },
    theme() {
      return Theme;
    },
    isIpad() {
      let isIpad = false;
      if (navigator.userAgent.indexOf("iPad") != -1) {
        isIpad = true;
      }
      return isIpad;
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    showLink() {
      let url = "https://blog.ivy-way.com/toefl-cn/";
      if (this.lang.value === "ZH-CN") {
        url = url + "?lang=cn";
      } else if (this.lang.value === "EN-US") {
        url = url + "?lang=en";
      }
      return url;
    },
    showHeader() {
      if (this.$route.meta.showHeader === undefined) {
        return true;
      } else {
        return false;
      }
    },
    showFooter() {
      if (this.$route.meta.showFooter === undefined) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    ...mapActions("user", ["changeLang", "logout", "ivyWayLogin"]),

    async handleIvyWayLogin(token) {
      await this.ivyWayLogin({
        token
      });
      await this.$store.dispatch("user/getProfile");
      await this.resetPageParam();
      this.showMain = true;
    },
    resetPageParam() {
      var url = new URL(window.location.href);
      var params = new URLSearchParams(url.search);
      params.set("token", "");
      url.search = params.toString();
      window.history.replaceState("", "", url.href);
    },
    async goToFeedbackPage() {
      window.open("mailto:info@xmocks.ai", "_blank");
    },
    foundAdBlockResult(result) {
      this.existAdBlock = result;
    },
    getSimpleTitle(routeName) {
      if (routeName === "Profile") {
        return user.displayName(
          this.profile.first_name,
          this.profile.last_name
        );
      }

      const simpleTitleLocalKeyMap = {
        LandingPage: "toefl.index.title_new",
        SatTestResults: "toefl.index.title_new",
        ToeflProfileBalance: "toefl.Balance",
        ToeflScorereport: "pageTitle.scoreReport",
        result: "pageTitle.satTestResults",
        ResultPDF: "pageTitle.satTestResults",
        Gradingsamples: "GradingSamples.TOEFL Writing Grading",
        Resolve: "pageTitle.explanation"
      };
      return this.$t(
        simpleTitleLocalKeyMap[routeName] || simpleTitleLocalKeyMap.LandingPage
      );
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/style";

.el-header {
  // box-shadow: 0 5px 5px #aaa;
  background-color: white;
  position: fixed;
  width: 100%;
  z-index: 1001;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #ccc;
}

header.el-header {
  padding: 0;
  margin: 0;
}

main.el-main {
  min-height: calc(100vh - 74px);
  padding: 0;
  // background-color: rgba(244, 245, 245, 0.5);
}

.container {
  // padding: 0 2rem;
  padding-top: 40px;
}
@media screen and (max-width: 992px) {
  main.el-main {
    padding-top: 60px;
  }
  // .toefl-welcome {
  //   padding: 0 15px;
  // }
}

.toefl-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 25px;
}
.toefl-welcome {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>
<style scoped>
::v-deep #feedback .el-dialog__body {
  padding: 5px 40px;
}
::v-deep #feedback .el-alert {
  padding: 0px 16px;
}

.contact-help {
  right: 40px;
  bottom: 160px;
  position: fixed;
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: var(--themeColor);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0 0 6px rgb(0 0 0 / 12%);
  cursor: pointer;
  z-index: 100;
}
::v-deep .el-alert .el-alert__description {
  margin-top: 0;
}
::v-deep .el-upload-dragger {
  width: 450px;
  height: 200px;
}
@media screen and (max-width: 736px) {
  ::v-deep .el-dialog--center .el-dialog__body {
    padding: 5px 20px;
  }
  ::v-deep .el-form--label-left .el-form-item__label {
    display: block;
    width: 100%;
    line-height: normal;
  }
  ::v-deep .el-upload-dragger {
    width: 100%;
    height: 150px;
  }
  ::v-deep .el-upload-dragger .el-icon-upload {
    margin: 20px 0 16px;
  }
  ::v-deep .el-upload {
    width: 100%;
  }
  ::v-deep .el-form-item {
    margin-bottom: 12px;
  }
  .contact-help {
    right: 15px;
    bottom: 100px;
  }
  ::v-deep #feedback .el-dialog__body {
    padding: 5px 15px;
  }
  ::v-deep.el-button + .el-button {
    margin-left: 0;
  }
}
</style>
